<template>
    <div class="h-full w-full">
        <loader :loading="loading" />
        <chart-col-compare chartId="salesCompare" :data="salesGraph" />
    </div>
</template>

<script>

import { state } from '@/store';

export default {
    
    data() {
        return {
           salesGraph: false,
           loading: false
        }
    },
    methods: {
        load() {

            this.loading = true
            
            // if(state.rolSelected.role_type_link != 'presidencia'){
            
                this.axios.get('pipeline/sales_graph', {params: this.params})
                .then(response => {
                    this.salesGraph = response.data.data
                })
            // }
            
            this.loading = false

        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{sectorAmbiciona: this.$route.params.catId}}
            }
            return params
        },
    },
    watch:{
        params() { this.load() }
    },
    mounted() { this.load() }
}

</script>